.things {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 25%;
    height: 17vw;
    transition: .3s;

}

.things_sq1 {
    position: absolute;
    left: 6%;
    display: flex;
    color: black;
    width: 85%;
    height: 80%;
    background-color: black;
    opacity: .04;
}

.things_sq2 {
    position: absolute;
    left: 9%;
    top: 4.5%;
    color: black;
    width: 85%;
    height: 90%;
    background-color: black;
    opacity: .04;
}

.things_sq3 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: white;
    width: 95%;
    height: 21%;
    bottom: 0;
    right: 2.5%;
    background-color: black;
    background-color: #C45355;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Fugaz One';
    font-weight: 400;
    font-size: calc(.5rem + 2vw);
    z-index: 2;
}

.things_img {
    position: absolute;
    top: -1rem;
    width: 55%;
    z-index: 0;
    left: 22.5%;
}

.things:hover {
    transform: scale(1.1, 1.1);
    cursor: pointer;
}

@media (max-width: 740px) {
    .things_sq3 {
        font-size: calc(.5rem + 1.25vw);
    }
}

@media (max-width: 630px) {
    .things_sq3 {
        font-size: calc(.5rem + 1vw);
        width: 100%;
    }
}