.crown_banner {
    position: fixed;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 16vh;
    color: white;
    background: rgb(196, 83, 85, 1);
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
    padding-top: 3.5%;
    font-size: 3rem;
    font-family: var(--fugaz);
    align-items: center;
    justify-content: center;
}

.bbcrown_banner {
    position: sticky;
    z-index: 0;
    display: flex;
    width: 100%;
    height: 16vh;
    color: white;
    background: rgb(196, 83, 85, 1);
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
    font-size: calc(1rem + 2vw);
    font-family: var(--fugaz);
    align-items: center;
    justify-content: center;
    margin-bottom: -4%;
}

.crown_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.image_container {
    width: 95%;
    height: 95%;
    transition: .3s;
    border-radius: 1%;
    border: 8px solid rgba(0, 0, 0, 0.07);
}

.image_container_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    transition: .25s;
    border-radius: 1%;
    opacity: .95;
    display: grid;
    grid-auto-rows: minmax(.05%, auto);
    margin-top: 10%;
    margin-bottom: 5%;
    margin-right: 2.5%;
    margin-left: 2.5%;
    grid-template-columns: repeat(3, 1fr);
}


.page_animate {
    position: fixed;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    transition: 1s ease-in;
    background-color: rgb(247, 247, 247);
    animation-name: page_animate;
    animation-duration: 1s;
    animation-direction: alternate;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    pointer-events: none;
}

.crowny {
    z-index: 0;
    display: none;
}

.navi {
    display: none;
}

.footy {
    display: none;
}

@keyframes page_animate {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@media (max-width: 1000px) {
    .bbcrown_banner {
        height: 13vh;
    }
}

@media (max-width: 700px) {
    .bbcrown_banner {
        height: 10vh;
    }
}

@media (max-width: 600px) {
    .crown_container_images {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 1400px) {
    .crown_banner {
        height: 16vh;
        padding-top: 7%;
        padding-bottom: 2%;
        font-size: 3rem;
    }
}

@media (max-width: 1100px) {
    .image_container_container {
        margin-top: 14%;
    }
}

@media (max-width: 950px) {
    .crown_banner {
        height: 16vh;
        font-size: 2rem;
    }
}


@media (max-width: 900px) {
    .crown_banner {
        height: 16vh;
        padding-top: 9%;
        padding-bottom: 2%;
    }
}

@media (max-width: 750px) {
    .crown_container_images {
        margin-top: 6%;
    }
}

@media (max-width: 700px) {
    .crown_banner {
        height: 16vh;
        padding-top: 11%;
        padding-bottom: 2%;
    }
}

@media (max-width: 600px) {
    .crown_container_images {
        margin-top: 10%;
    }

    .image_container_container {
        grid-template-columns: repeat(2, 1fr);

    }
}

@media (max-width: 500px) {
    .crown_banner {
        padding-top: 15%;
        padding-bottom: 2%;
    }
}

@media (max-width: 470px) {
    .crown_container_images {
        margin-top: 15%;
    }
}

@media (max-width: 380px) {
    .crown_banner {
        padding-top: 18%;
        padding-bottom: 2%;
        font-size: 1.5rem;
    }

    .crown_container_images {
        margin-top: 20%;
    }
}


@media (max-width: 320px) {
    .crown_banner {
        padding-top: 18%;
        padding-bottom: 2%;
    }

    .crown_container_images {
        margin-top: 27%;
    }
}