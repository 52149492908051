.tredsq {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: .3s;

}

.tred_sq1 {
    position: absolute;
    left: 6%;
    display: flex;
    color: black;
    width: 85%;
    height: 100%;
    background-color: #CF5D5F;
}

.tred_sq2 {
    position: absolute;
    left: 2%;
    top: 4.5%;
    color: black;
    width: 85%;
    height: 90%;
}


.tredsq:hover {
    transform: scale(1.05);
    opacity: .95;
}