.catalog {
    width: 100%;
    height: 100%;
    background: linear-gradient(247.98deg, #F2F2F2 15.95%, rgba(247, 247, 247, 0) 78.07%);
}

.catalog_banner {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--fugaz);
    width: 100%;
    height: 20vh;
    background-image: url('../assets/catalog_header_bg.jpg');
    background-position: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03), 0px 7px 4px rgba(0, 0, 0, 0.1);

}

.catalog_banner h1 {
    color: #f4f4f4;
    font-size: calc(1rem + 4vw);
    margin-top: 3%;
    text-shadow: -3px -3px 0 #303030, 3px -3px 0 #303030, -3px 3px 0 #303030, 3px 3px 0 #303030;

}

.catalog_items_container {
    padding-top: 2%;
    width: 100%;
}

@media (max-width: 1200px) {
    .catalog_header h1 {
        margin-top: 2%;
    }
}

@media (max-width: 840px) {
    .catalog_header h1 {
        margin-top: 3%;
    }
}

@media (max-width: 700px) {
    .catalog_header h1 {
        margin-top: 4%;
    }
}

@media (max-width: 600px) {
    .catalog_header h1 {
        margin-top: 5%;
    }
}