.about {
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
    background: linear-gradient(247.98deg, #F2F2F2 15.95%, rgba(247, 247, 247, 0) 78.07%);
}

.about_banner {
    display: flex;
    width: 100%;
    height: 16vh;
    color: white;
    background: rgb(196, 83, 85, 1);
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
    padding-top: 3.5%;
    font-size: calc(1rem + 2vw);
    font-family: var(--fugaz);
    align-items: center;
    justify-content: center;
}

.about_right h2 {
    margin-bottom: 2%;
}

.partner_banner {
    position: sticky;
    z-index: 0;
    display: flex;
    width: 100%;
    height: 16vh;
    color: white;
    background: rgb(196, 83, 85, 1);
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
    font-size: calc(1rem + 2vw);
    font-family: var(--fugaz);
    align-items: center;
    justify-content: center;
    margin-bottom: -4%;
}

.about_container {
    display: flex;
    width: 100%;
    gap: 5%;
    margin-top: 12%;
    margin-bottom: 15%;
    align-items: center;
    justify-content: center;
}

.about_left {
    width: 38%;
    margin-left: 3%;
}

.about_right {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #303030;
    font-family: var(--inter);
    font-size: calc(.5rem + .75vw);

}

.partners_banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10%;
    background: linear-gradient(89.79deg, #BD5254 -10.94%, #F57476 92.85%);
    height: 9vw;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 7px 4px rgba(0, 0, 0, 0.15);
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: calc(1rem + 1vw);
    color: white;
    text-shadow: -3px -3px 0 #303030, 3px -3px 0 #303030, -3px 3px 0 #303030, 3px 3px 0 #303030;
}

.partners {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15%;
    width: 100%;
    gap: 6%;
    margin-bottom: 15%;

}

.partners_right {
    width: 60%;
    margin-right: -20%;
}

.partners_left {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #303030;
    font-family: var(--inter);
    font-size: calc(.6rem + .75vw);
}

.partners_left span {
    font-family: var(--fugaz);
}

.accoya_buttons {
    width: 100%;
    display: flex;
    gap: 10%;
    justify-content: flex-start;
    margin-top: 4%;
}

.accoya_btn {
    padding-right: 5%;
    padding-left: 5%;
    background-color: #CF5D5F;
    border: none;
    color: white;
    font-size: calc(1rem + 1.5vw);
    font-family: var(--fugaz);
    margin-top: 4%;
    transition: .2s;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.accoyabtn {
    margin-right: 5%;
}

.accoya_btn:hover {
    background-color: #ee6567;
    font-size: calc(1rem + 1.5vw);
    cursor: pointer;
}

/* Partners banner queries*/
@media (max-width: 1000px) {
    .partner_banner {
        height: 13vh;
    }

}

@media (max-width: 800px) {
    .about_right {
        width: 50%;
        font-size: calc(.5rem + .75vw);
    }

    .partners_left {
        width: 50%;
        margin-left: 5%;
        font-size: calc(.6rem + .75vw);
    }

    .partners {
        gap: 2%;
    }

}

@media (max-width: 700px) {
    .about_right {
        width: 50%;
        font-size: calc(.5rem + .75vw);
    }

    .partners_left {
        width: 50%;
        margin-left: 5%;
        font-size: calc(.5rem + .55vw);
    }

    .partners {
        gap: 2%;
    }

    .accoya_btn {
        padding-right: 5%;
        padding-left: 5%;
        background-color: #CF5D5F;
        border: none;
        color: white;
        font-size: calc(.75rem + 1.25vw);
        font-family: var(--fugaz);
        margin-top: 4%;
        transition: .2s;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .partner_banner {
        height: 10vh;
    }

}


@media (max-width: 500px) {
    .partners {
        flex-direction: column;
        flex-direction: column-reverse;
    }

    .partners_right {
        margin-bottom: 5%;
    }

    .about_container {
        flex-direction: column;
    }

    .about_right {
        margin-top: 2.5%;
    }

    .about_left {
        margin-right: 8%;
    }
}

@media (max-width: 425px) {
    .about_right {
        font-size: calc(.4rem + .75vw);
    }
}

/* */

@media (max-width: 1400px) {
    .about_banner {
        height: 16vh;
        padding-top: 7%;
        padding-bottom: 2%;
        font-size: 3rem;
    }
}


@media (max-width: 950px) {
    .about_banner {
        height: 16vh;
        font-size: 2rem;
    }
}


@media (max-width: 900px) {
    .about_banner {
        height: 16vh;
        padding-top: 9%;
        padding-bottom: 2%;
    }
}


@media (max-width: 700px) {
    .about_banner {
        height: 16vh;
        padding-top: 11%;
        padding-bottom: 2%;
    }
}


@media (max-width: 500px) {
    .about_banner {
        padding-top: 15%;
        padding-bottom: 2%;
    }
}

@media (max-width: 380px) {
    .about_banner {
        padding-top: 18%;
        padding-bottom: 2%;
        font-size: 1.5rem;
    }
}

@media (max-width: 320px) {
    .about_banner {
        padding-top: 18%;
        padding-bottom: 2%;
    }

}

/*
.about_left,
.about_right,
.partners_left,
.partners_right {
    outline: 1px solid limegreen !important;
}

*/