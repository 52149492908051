.home_three {
    display: flex;
    flex-direction: column;
    margin-top: 8rem;
}

.home_three_bar {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(270.11deg, #953335 7.07%, #C55355 93.24%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 10vw;
    color: white;
    font-family: var(--fugaz);
    font-size: calc(1rem + 6vw);
}

.home_three_banner {
    width: 100%;
}

.home_three_rows {
    margin-bottom: 8%;
}

.home_three_row1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 6%;
    margin-top: 5rem;
}

.home_three_row1_2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 8%;
    gap: 8%;
    margin-top: 5rem;
}

@media (max-width: 600px) {
    .home_three_bar {
        font-size: calc(1rem + 4vw);

    }

    .home_three_rows {
        margin-bottom: 12%;
    }

}

@media (max-width: 400px) {

    .home_three_rows {
        margin-bottom: 16%;
    }

}