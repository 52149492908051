.login {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.login label {
    font-size: calc(.5rem + 1vw);
    margin-bottom: 5%;
    font-family: var(--fugaz);
    color: grey;
    text-align: center;
}

.login form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 20%;
    gap: 1rem;
}

.login input {
    width: 100%;
    height: 5vh;
    padding-left: 1.5%;
    font-size: calc(.25rem + .75vw);
    font-family: var(--fugaz);
    color: grey;
}

.login input::placeholder {
    opacity: .6;
}

.login button {
    width: 100%;
    border: none;
    background-color: rgb(204, 91, 91);
    font-size: calc(.5rem + 1vw);
    font-family: var(--fugaz);
    color: white;
    box-shadow: 4px black;
}

.login button:hover {
    cursor: pointer;
    background-color: rgb(218, 97, 97);
}

.login span {
    font-size: 1rem;
    color: red;
    font-family: var(--fugaz);
}

@media (max-width: 1150px) {
    .login label {
        font-size: calc(.5rem + .75vw);

    }
}

@media (max-width: 850px) {
    .login form {
        width: 40%;
    }

    .login label {
        font-size: calc(.5rem + 1.25vw);
    }
}

@media (max-width: 500px) {
    .login form {
        width: 60%;
    }

    .login label {
        font-size: calc(.5rem + 2vw);
    }

    .login button {
        font-size: calc(.5rem + 1.75vw);

    }

    .login input {
        font-size: calc(.5rem + 1vw);
    }
}