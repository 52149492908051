.footer {
    display: flex;
    align-items: center;
    background: linear-gradient(270.11deg, #953335 7.07%, #C55355 93.24%);
    box-shadow: 0px -8px 8px rgba(0, 0, 0, 0.15);
    height: 25%;
    position: absolute;
    width: 100%;
}

.footerbg {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: .2;
}

.footer_text {
    position: absolute;
    z-index: 2;
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: calc(.2rem + 1vw);
    color: #FFFFFF;
}

.footer_row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: .3%;
}

.footer_row_two {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
}

.footer_element {
    display: flex;
    align-items: center;
    width: 50%;
    gap: 5%;
}

.footer_text img {
    width: 10%;
}

@media (max-width: 860px) {

    .footer_text {
        font-size: calc(.3rem + 1vw);

    }

    .footer_text img {
        width: 15%;
    }
}

@media (max-width: 600px) {
    .footer_text {
        font-size: calc(.2rem + 1vw);
    }
}

@media (max-width: 400px) {
    .footer_row {
        padding-right: 1%;
        padding-left: 1%;
        margin-bottom: 3%;
    }

    .footer_row_two {
        padding-right: 1%;
        padding-left: 1%;
    }

    .footer_element {
        padding-right: 0;
    }

    .footer_text {
        font-size: .45rem;
    }
}

@media (max-width: 380px) {
    .footer_row {
        gap: 6%;
    }

    .footer_row_two {
        gap: 6%;
    }
}

@media (max-width: 340px) {
    .footer_row {
        gap: 9%;
    }

    .footer_row_two {
        gap: 9%;
    }

    .footer_text {
        font-size: calc(.2rem + .9vw);
    }
}