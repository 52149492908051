.gallery {
    width: 100%;
    height: 100%;
}

.gallery_container {
    display: flex;
    flex-direction: column;
}

.gallery_container img {
    transition: .1s;

}

.gallery_container img:hover {
    opacity: .9;
    cursor: pointer;
}

.gallery_row1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 7vw;
    gap: .3%;
    margin-top: .3%;
}

.gallery_row1 img {
    height: 26vw;
}

.gallery_row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 7vw;
    gap: .3%;
    margin-top: 19.5%;
}

.gallery_row2 img {
    height: 29.86vw;
}

.gallery_row3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 7vw;
    gap: .3%;
    margin-top: 23.4%;
}

.gallery_row3 img {
    height: 22.05vw;
}

.gallery_row4 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 7vw;
    gap: .3%;
    margin-top: 15.5%;
    margin-bottom: 20.8%;
}

.gallery_row4 img {
    height: 27.2vw;
}

.modal {
    position: fixed;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    transition: 1s ease-in;
    background-color: rgba(0, 0, 0, 0.75);
    animation-name: modal;
    animation-duration: .15s;
    animation-direction: alternate;
    animation-fill-mode: both;
    animation-iteration-count: 1;
}

.modal_inner {
    transition: .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #953335;
    border-radius: 3px;
    padding-left: 1%;
    padding-right: 1%;
    animation-name: modal_inner;
    animation-duration: .5s;
    animation-direction: alternate;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    height: 80%;
}

.modal_inner img {
    height: 95%;
    border-radius: 3px;
}

.modal_inner:hover {
    transform: scale(1.1, 1.1);
}

@media (max-width: 1000px) {
    .modal_inner {
        height: 70%;
    }
}

@media (max-width: 850px) {
    .modal_inner {
        height: 60%;
    }
}

@media (max-width: 750px) {
    .modal_inner {
        height: 50%;
    }
}

@media (max-width: 650px) {
    .modal_inner {
        height: 40%;
    }
}

@media (max-width: 480px) {
    .modal_inner {
        height: 30%;
    }
}

@media (max-width: 370px) {
    .modal_inner {
        height: 25%;
    }
}

@media (max-width: 300px) {
    .modal_inner {
        height: 22%;
    }
}



@keyframes modal {
    0% {
        opacity: 0;

    }

    100% {
        opacity: 1;

    }
}

@keyframes modal_inner {
    0% {
        opacity: 0;

    }

    100% {
        opacity: 1;

    }
}