.home_two {
    display: flex;
    flex-direction: column;
}

.home_two_text {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    gap: 7%;
    margin-top: 10%;
    color: #303030;
}

.home_two_text_1,
.home_two_text_2,
.home_two_text_3 {
    width: 25%;
}

.home_two_text h1 {
    font-weight: 100;
    font-family: var(--fugaz);
    font-size: calc(.5rem + 1.5vw);
}

.home_two_text p {
    font-family: var(--inter);
    font-size: calc(.55rem + 1vw);
}

.home_two_w {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 7%;
    margin-top: 5%;
}

.moe {
    width: 300%;
}

@media (max-width: 690px) {
    .home_two_text p {
        font-family: var(--inter);
        font-size: calc(.4rem + 1vw);
    }
}

@media (max-width: 525px) {
    .home_two {
        margin-top: 5%;
        margin-bottom: -5%;
    }
}

@media (max-width: 400px) {
    .home_two {
        margin-top: 7%;
        margin-bottom: -15%;
    }
}