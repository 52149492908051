.navbar {
    display: flex;
    position: fixed;
    z-index: 5;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 3rem;
    background-color: #953335;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
    box-shadow: 0px 6px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
    position: fixed;
}

.navbar_left {
    width: 12%;
}

.navbar_left img {
    width: 8rem;
    margin-left: 2rem;
    margin-top: 3rem;
}

.navbar_right {
    display: flex;
    width: 88%;
    font-size: 100%;
    color: white;
    font-family: var(--inter);
    font-size: calc(.5rem + 1vw);
    justify-content: center;
    gap: 5rem;
}

.navbar_links:hover {
    color: rgb(219, 219, 219);
}

.navbar_right_hamburger {
    width: 4rem;
    height: 3rem;
    display: none;
    flex-direction: column;
    justify-content: center;
    gap: 22%;
    margin-right: .5rem;
}

.navbar_right_hamburger span {
    width: 45px;
    height: 3px;
    background: white;
    border-radius: 1rem;
}

.navbar_right_hamburger:hover {
    cursor: pointer;
}

.navbar_right_hamburger_clicked {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 100%;
    height: 100vh;
    background-color: #953335;
    flex-direction: column;
    z-index: 5;
}

.navbar_right_hamburger_clicked_links {
    font-size: 2rem;
    color: white;
    font-family: var(--inter)
}

.navbar_right_hamburger_clicked_links:hover {
    color: rgb(219, 219, 219);
}

.navbar_right_hamburger_clicked img {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 2rem;
}

.navbar_right_hamburger_clicked img:hover {
    cursor: pointer;
}

@media (min-width: 1200px) {
    .navbar_right {
        margin-left: -5%;
    }
}

@media (max-width: 1100px) {
    .navbar_right {
        gap: 3rem;
    }
}

@media (max-width: 950px) {
    .navbar_right {
        gap: 2rem;
    }

    .navbar_left img {
        width: 6rem;
        margin-top: 2rem;
        margin-left: 1.5rem;
    }
}

@media (max-width: 800px) {
    .navbar_right {
        gap: 1.5rem;
        font-size: calc(.6rem + 1vw);
    }
}

@media (max-width: 550px) {
    .navbar_right_hamburger {
        display: flex
    }

    .navbar_links {
        display: none;
    }
}

@media (max-width: 665px) {
    .navbar_right {
        font-size: calc(.5rem + 1vw);
        margin-left: 2rem;
        gap: 1rem;
    }
}

@media (max-width: 450px) {
    .navbar_left img {
        width: 5rem;
        margin-top: 1rem;
        margin-left: .8rem;
    }
}

@media (max-width: 400px) {
    .navbar_left img {
        width: 5rem;
        margin-top: 2rem;
        margin-left: 1rem;
    }
}