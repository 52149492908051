.cimg {
    width: 100%;
    height: 100%;
}

.image_cont {
    width: 95%;
    height: 95%;
    transition: .3s;
    border-radius: 1%;
    border: 8px solid rgba(0, 0, 0, 0.07);
}

.image_cont:hover {
    transform: scale(1.05, 1.05);
    border: 8px solid rgba(0, 0, 0, 0.25);
}

.cmodal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 500;
    background-color: rgba(0, 0, 0, 0.75);
    transition: 1s ease-in;
    animation-name: modal;
    animation-duration: .15s;
    animation-direction: alternate;
    animation-fill-mode: both;
    animation-iteration-count: 1;
}

.cmodal_inner {
    transition: .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #953335;
    border-radius: 3px;
    padding-left: 1%;
    padding-right: 1%;
    animation-name: modal_inner;
    animation-duration: .5s;
    animation-direction: alternate;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    height: 80%;
}


.cmodal_inner img {
    height: 95%;
    border-radius: 3px;
}

.cmodal_inner:hover {
    transform: scale(1.1, 1.1);
}

@media (max-width: 1000px) {
    .cmodal_inner {
        height: 70%;
    }
}

@media (max-width: 850px) {
    .cmodal_inner {
        height: 60%;
    }
}

@media (max-width: 750px) {
    .cmodal_inner {
        height: 50%;
    }
}

@media (max-width: 650px) {
    .cmodal_inner {
        height: 40%;
    }
}

@media (max-width: 480px) {
    .cmodal_inner {
        height: 30%;
    }
}

@media (max-width: 370px) {
    .cmodal_inner {
        height: 25%;
    }
}

@media (max-width: 300px) {
    .cmodal_inner {
        height: 22%;
    }
}



@keyframes modal {
    0% {
        opacity: 0;

    }

    100% {
        opacity: 1;

    }
}

@keyframes modal_inner {
    0% {
        opacity: 0;

    }

    100% {
        opacity: 1;

    }
}