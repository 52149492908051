.home_one {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background: linear-gradient(247.98deg, #F2F2F2 15.95%, rgba(247, 247, 247, 0) 78.07%);
    gap: 1.4rem;
}

.home_one_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12%;
    color: #303030;
}

.home_one_text h1 {
    font-size: 4rem;
    font-size: calc(1rem + 3vw);
    font-family: var(--fugaz);
    font-weight: 100;
}

.home_one_text_two {

    font-size: 2rem;
    font-size: calc(1rem + 1vw);
    font-family: var(--inter);
    font-weight: 500;
}

.home_one_text_three {
    font-size: 2rem;
    font-size: calc(1rem + 1vw);
    font-family: var(--inter);
    font-weight: 500;
}

.home_one_button {
    color: white;
    font-size: calc(1.5rem + 1vw);
    font-family: var(--fugaz);
    background: #A03234;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    width: 30%;
    height: 9%;
    transition: .2s ease;
}

.home_one_button:hover {
    cursor: pointer;
    background: #ba3638;
}

.home_one_diag {
    display: flex;
    width: 100%;
    height: 30%;
    background: linear-gradient(270.11deg, #953335 7.07%, #C55355 93.24%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 7px 4px rgba(0, 0, 0, 0.25);
    transform: skewY(7deg);
    margin-top: 12%;
    font-size: calc(3.5rem + 3.5vw);
    font-family: var(--fugaz);
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.home_one_gear_one {
    position: absolute;
    opacity: .08;
    z-index: 0;
    width: 25%;
    top: 74%;
    left: 68%;
    transform: rotate(180deg);
    animation: rotate-animation 40s infinite linear;
}

.home_one_gear_two {
    position: absolute;
    opacity: .05;
    z-index: 0;
    width: 26%;
    top: 55%;
    right: 80%;
    transform: rotate(180deg);
    animation: rotate-animation 50s infinite linear;
}

@media (max-width: 1200px) {
    .home_one_gear_one {
        width: 25%;
        top: 74%;
        left: 69.8%;
    }

    .home_one_gear_two {
        top: 55%;
        right: 80%;
        width: 26%;
    }
}

@media (max-width: 1100px) {
    .home_one_gear_one {
        width: 22%;
        top: 65%;
        left: 74%;
    }

    .home_one_gear_two {
        top: 50%;
        right: 82%;
        width: 22%;
    }
}

@media (max-width: 950px) {
    .home_one_gear_one {
        top: 60%;
        left: 74%;
    }

    .home_one_gear_two {
        top: 45%;
        right: 82%;
    }
}

@media (max-width: 900px) {
    .home_one_text_two {
        font-size: calc(.7rem + .7vw);

    }

    .home_one_text_three {
        font-size: calc(.7rem + .7vw);
    }

    .home_one_button {
        font-size: calc(1rem + 1vw);

    }

    .home_one_gear_two {
        top: 40%;
    }

    .home_one_gear_one {
        top: 54%;
        left: 75.8%;
        width: 20%;
    }

    .home_one_diag {
        font-size: calc(2.5rem + 2.5vw);
    }
}

@media (max-width: 800px) {
    .home_one_gear_one {
        top: 50%;
        left: 76.5%;
    }

    .home_one_gear_two {
        top: 35%;
        right: 82%;
    }
}

@media (max-width: 700px) {
    .home_one_text {
        margin-top: 20%;
    }

    .home_one_diag {
        font-size: calc(2rem + 2.5vw);
    }

    .home_one_gear_one {
        top: 55%;
        left: 75.55%;
    }

    .home_one_gear_two {
        top: 42%;
        right: 82%;
    }
}

@media (max-width: 650px) {
    .home_one_gear_one {
        top: 51%;
        left: 75.55%;
    }

    .home_one_gear_two {
        top: 40%;
        right: 82%;
    }
}


@media (max-width: 550px) {
    .home_one_diag {
        height: 10%;
        font-size: calc(2rem + 2vw);
    }

    .home_one_text h1 {
        font-size: calc(1rem + 2vw);
    }

    .home_one_text_two {
        font-size: calc(.5rem + .5vw);
    }

    .home_one_text_three {
        font-size: calc(.5rem + .5vw);

    }

    .home_one_gear_one {
        top: 43%;
    }

    .home_one_gear_two {
        top: 32%;
    }

    .home_one_button {
        height: 6%;
        font-size: calc(.8rem + .8vw);

    }
}

@media (max-width: 500px) {
    .home_one_gear_one {
        top: 40%;
    }

    .home_one_gear_two {
        top: 32%;
    }
}

@media (max-width: 450px) {
    .home_one_text {
        margin-top: 25%;
    }

    .home_one_gear_one {
        top: 42%;
    }

    .home_one_gear_two {
        top: 33%;
    }
}

@media (max-width: 400px) {
    .home_one_gear_one {
        top: 40%;
    }

    .home_one_gear_two {
        top: 32%;
    }
}

@media (max-width: 380px) {
    .home_one_gear_one {
        top: 38%;
    }

    .home_one_gear_two {
        top: 31%;
    }

    .home_one_text {
        margin-top: 30%;
    }

    .home_one_text h1 {
        font-size: calc(.7rem + 1.5vw);
    }

    .home_one_text_two {
        font-size: calc(.4rem + .4vw);
    }

    .home_one_text_three {
        font-size: calc(.4rem + .4vw);
    }

    .home_one_button {
        font-size: calc(.65rem + .65vw);
        width: 40%;
        height: 4%;
    }

    .home_one_diag {
        font-size: calc(1.5rem + 1.5vw);
    }
}

@media (max-width: 340px) {
    .home_one_gear_one {
        top: 35%;
    }

    .home_one_gear_two {
        top: 28%;
    }
}

@media (max-width: 300px) {
    .home_one_gear_one {
        top: 34%;
    }

    .home_one_gear_two {
        top: 28%;
    }
}

@keyframes rotate-animation {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}